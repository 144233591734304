$large-screen: 1200px;
$mid-screen: 991px;
$small-screen: 767px;
$modal-fade-transform: scale(.8);


$royalblueTransparent: rgba(0,86,179,.88);
$lanaiblueTransparent: rgba(0, 114, 191, 0.8);
$royalblue: #0056b3;
$lanaiblue: #0072bf;

$primaryColor: $royalblue;
$primaryColorTransparent:$royalblueTransparent;


$items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

$primaryFont: 'Poppins', 'Helvetica', 'Arial', sans-serif;
$secondaryFont: 'Poppins', 'Helvetica', 'Arial', sans-serif;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering:optimizeLegibility;
}

body.modal-open {
  overflow: hidden;
}

#toggle {
    display: none; //hides the checkbox
  }
  
  
  // Styles for the 'open' state, if the checkbox is checked
  #toggle:checked {
    //Any element you need to change the style if menu is open goes here, using the sibling selector (~)
    & ~ .toggle-container .button-toggle {
        transform: scale(40);
        background-color: #0056b3;
        -webkit-transform: scale(40);
        -moz-transform: scale(40);
        -ms-transform: scale(40);
        -o-transform: scale(40);
}

    & ~ .toggle-container .x {
        &:before {
            background-color: white;
            -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
               -moz-transform: translateY(-50%) rotate(45deg) scale(1);
                -ms-transform: translateY(-50%) rotate(45deg) scale(1);
                 -o-transform: translateY(-50%) rotate(45deg) scale(1);
                    transform: translateY(-50%) rotate(45deg) scale(1);
          }
      
          &:after {
            background-color: white;
            -webkit-transform: translateY(-50%) rotate(-45deg) scale(1);
               -moz-transform: translateY(-50%) rotate(-45deg) scale(1);
                -ms-transform: translateY(-50%) rotate(-45deg) scale(1);
                 -o-transform: translateY(-50%) rotate(-45deg) scale(1);
                    transform: translateY(-50%) rotate(-45deg) scale(1);
          }
    }
  
    // Open Nav
    & ~ .nav-mobile {
      margin-bottom: 100px;
      pointer-events: auto;
      margin: 115px 25px 20px;
      -webkit-transform: translate(50px, 50px);
         -moz-transform: translate(50px, 50px);
          -ms-transform: translate(50px, 50px);
           -o-transform: translate(50px, 50px);
              transform: translate(50px, 50px);
  
      // Restoring nav items from 'lines' in the menu icon
      .nav-item-mobile {
        color: white;
        letter-spacing: 0;
        height: 50px;
        line-height: 40px;
        margin-top: 0;
        opacity: 1;
        -webkit-transform: scaleY(1);
           -moz-transform: scaleY(1);
            -ms-transform: scaleY(1);
             -o-transform: scaleY(1);
                transform: scaleY(1);
        -webkit-transition: $transition-duration, opacity 0.1s;
        -o-transition: $transition-duration, opacity 0.1s;
        -moz-transition: $transition-duration, opacity 0.1s;
        transition: $transition-duration, opacity 0.1s;
  
  
        // Setting delays for the nav items in open transition
        @for $i from 1 through $items {
          &:nth-child(#{$i}) {
            $delay: ($items - $i) * $transition-delay;
            -webkit-transition-delay: $delay;
               -moz-transition-delay: $delay;
                 -o-transition-delay: $delay;
                    transition-delay: $delay;
            &:before {
              -webkit-transition-delay: $delay;
                 -moz-transition-delay: $delay;
                   -o-transition-delay: $delay;
                      transition-delay: $delay;
            }
          }
        }
  
        // Hide the lines
        &:before {
          opacity: 0;
        }
      }
    }
  }
  

.button-toggle {
    position: absolute;
    display: inline-block;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin: 25px;
    margin-top: -5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    left: 20px;
    margin: 0;
    margin-top: -5px;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
            -webkit-transition: 0.3s linear;
            -o-transition: 0.3s linear;
            -moz-transition: 0.3s linear;
            transition: 0.3s linear;
    @media (min-width: $mid-screen) {
        display: none;
    }
  
    // Shadow on Hover
    &:hover {
        -webkit-transform: scale(2);
           -moz-transform: scale(2);
            -ms-transform: scale(2);
             -o-transform: scale(2);
                transform: scale(2);
        background-color: rgba(0, 0, 0, 0.1);
    }
  
    
  }

  .x {
      // Making the 'X' icon using `:before` and `:after` pseudo-elements
    // Initially hidden beause of `scale(0)` transformation
    position: absolute;
    display: inline-block;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin: 25px;
    margin-top: -5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    left: 20px;
    margin: 0;
    margin-top: -5px;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    -webkit-transition: $transition-duration + 0.1;
    -o-transition: $transition-duration + 0.1;
    -moz-transition: $transition-duration + 0.1;
    transition: $transition-duration + 0.1;

    &:hover {
           transform: scale(1.2);
           -webkit-transform: scale(1.2);
           -moz-transform: scale(1.2);
           -ms-transform: scale(1.2);
           -o-transform: scale(1.2);
} 
  
    &:before, &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $primaryColor;
        -webkit-border-radius: 5px;
           -moz-border-radius: 5px;
                border-radius: 5px;
        -webkit-transition: $transition-duration;
        -o-transition: $transition-duration;
        -moz-transition: $transition-duration;
        transition: $transition-duration;
        
        
      }
      
    
      &:before {
        -webkit-transform: translateY(-50%) rotate(45deg) scale(0);
           -moz-transform: translateY(-50%) rotate(45deg) scale(0);
            -ms-transform: translateY(-50%) rotate(45deg) scale(0);
             -o-transform: translateY(-50%) rotate(45deg) scale(0);
                transform: translateY(-50%) rotate(45deg) scale(0);
      }
    
      &:after {
        -webkit-transform: translateY(50%) rotate(-45deg) scale(0);
           -moz-transform: translateY(50%) rotate(-45deg) scale(0);
            -ms-transform: translateY(50%) rotate(-45deg) scale(0);
             -o-transform: translateY(50%) rotate(-45deg) scale(0);
                transform: translateY(50%) rotate(-45deg) scale(0);
      }
  }
/* Menu */

.nav-mobile {
  display: inline-block;
  margin: 25px 25px 20px;
  pointer-events: none;
  position: absolute;
    left: 20px;
    margin: 0;
  -webkit-transition: $transition-duration;
  -o-transition: $transition-duration;
  -moz-transition: $transition-duration;
  transition: $transition-duration;
  z-index: 3;
  @media (min-width: $mid-screen) {
    display: none;
}
}

// Showing nav items as lines, making up the hamburger menu icon
.nav-item-mobile {
  position: relative;
  display: inline-block;
  float: left;
  clear: both;
  color: transparent;
  font-size: 16px;
  letter-spacing: -6.2px;
  height: 7px;
  line-height: 7px;
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-transform: scaleY(0.2);
     -moz-transform: scaleY(0.2);
      -ms-transform: scaleY(0.2);
       -o-transform: scaleY(0.2);
          transform: scaleY(0.2);
  -webkit-transition: $transition-duration, opacity 1s;
  -o-transition: $transition-duration, opacity 1s;
  -moz-transition: $transition-duration, opacity 1s;
  transition: $transition-duration, opacity 1s;

  //Set delays for the nav items in close transition
  @for $i from 1 through $items {
    &:nth-child(#{$i}) {
      $delay: ($i - 1) * $transition-delay;
      -webkit-transition-delay: $delay;
         -moz-transition-delay: $delay;
           -o-transition-delay: $delay;
              transition-delay: $delay;
      &:before {
        -webkit-transition-delay: $delay;
           -moz-transition-delay: $delay;
             -o-transition-delay: $delay;
                transition-delay: $delay;
      }
    }
  }

  // Adjusting width for first line
  &:nth-child(1) {
    letter-spacing: -8px;
  }

  // Adjusting width for second line
  &:nth-child(2) {
    letter-spacing: -7px;
  }

  // Adjusting the fourth element onwards
  &:nth-child(n + 4) {
    letter-spacing: -8px;
    margin-top: -7px;
    opacity: 0;
  }

  //getting lines for the hamburger menu icon
  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 80%;
    height: 2px;
    background-color: $primaryColor;
    -webkit-transform: translateY(-50%) scaleY(5);
       -moz-transform: translateY(-50%) scaleY(5);
        -ms-transform: translateY(-50%) scaleY(5);
         -o-transform: translateY(-50%) scaleY(5);
            transform: translateY(-50%) scaleY(5);
    -webkit-transition: $transition-duration;
    -o-transition: $transition-duration;
    -moz-transition: $transition-duration;
    transition: $transition-duration;
  }
}

body,
html {
    width: 100%;
    position: relative;
}
body {
    outline: none;
    font-family: $primaryFont;
}
.h1,
h1 {
    font-size: 45px;
}
.container--ycentered {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

.icon {
    font-weight: 400 !important;
}
.icon__reviews {
    font-size: 1.4em;
    vertical-align: text-top;
    margin: 0 4px;
}
#modular-lights > .active {
    transition: 1s all ease;
    -webkit-transition: 1s all ease;
    -moz-transition: 1s all ease;
    -ms-transition: 1s all ease;
    -o-transition: 1s all ease;
}

.desktop-only {
  display: block;
  @media (max-width: $mid-screen) {
    display: none;
  }
}

.fa-arrow-circle-left.desktop-only, .fa-arrow-circle-right.desktop-only {
  display: block !important;
  @media (max-width: $mid-screen) {
    display: none !important;
  }
}


.mobile-only {
  display: none;
  @media (max-width: $mid-screen) {
    display: block;
  }
}
.fa-chevron-left.mobile-only, .fa-chevron-right.mobile-only {
  display: none !important;
  @media (max-width: $mid-screen) {
    display: block !important;
  }
}

.icon__leave-review {
    margin-right: 5px;
    font-size: 12px;
    color: aquamarine;
}
.icon__phone { 
    -webkit-transform: rotate(100deg);
       -moz-transform: rotate(100deg);
        -ms-transform: rotate(100deg);
         -o-transform: rotate(100deg);
            transform: rotate(100deg);
    font-size: 10px;
    margin-right: 0.8em;
}
.icon__tour {
    margin-right: 10px;
}
.icon__features {
    margin: 0 10px 0 0;
}
.icon__active {
    color: white;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
    margin-left: 0;
}
.heading {
    font-family: $secondaryFont;
    font-weight: 600;
    margin: 0.4em 0;
}

.heading-wbackground {
    @media (min-width: $mid-screen) {
    background: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    }
}
.heading__tour-item {
    font-size: 14px;
    color: #ffffff;
    padding: 1em 0;
    position: absolute;
    bottom: 0px;
    left: 25px;
}

.tour-item {
    overflow: hidden;
    border-radius: 10px;
    padding: 0;
    background: transparent;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 300px;
}

.subheading-upper {
    font-size: 24px;
    font-family: $secondaryFont;
    font-weight: 600;
    @media (max-width: $mid-screen) { margin: 1em 0 0.2em;}
}

.primary-heading {
    color: $primaryColor;
    font-family: $primaryFont;
    max-width: 850px;
    margin: 0em auto;
    padding: 0;
    position: relative;
    text-align: center;
    font-size: 50px;
    line-height: 1.07143;
    font-weight: 600;
    color: #000;
    letter-spacing: -.005em;
    @media (max-width: $mid-screen) { font-size: 42px;
      font-weight: 700;} 
    @media (max-width: $small-screen) { font-size: 32px;}
}
 
.primary-heading__hero {
    letter-spacing: 3px;
    font-size: 5em;
    font-family: $secondaryFont;
    margin: 0.2em 0;
    opacity: 0.7;
    max-width: 1050px;
    @media (max-width: $mid-screen) { font-size: 58px;
      font-weight: 100;
      letter-spacing: .3px;}
    @media (max-width: $small-screen) { font-size: 38px;}
    @media (max-width: 550px) { font-size: 28px;}
}
.primary-heading__hero-shadow {
    position: absolute;
    color: #ff1212;
    top: 172px;
    left: 27.1%;
    display: none;
    z-index: -1;
}
.primary-heading__terms, .primary-heading__privacy {
    margin: .67em 0;
}

.large-heading {
    font-family: $secondaryFont;
    font-weight: 600;
    margin: 1em 0;
    font-size: 2em;
    color:#191b1d; 
    @media (max-width: 991px) { font-size: 32px;
        font-weight: 700;}
}

.heading--black {
    color:#191b1d;
}


.heading-left {
    padding: 0;
    margin: -0.04em;
    text-align: left;
}

.form-control {
    border: 1px solid $primaryColor;
}

.form__contact .invalid-feedback {
  color: white;
}

.primary-heading-container {
    @media (max-width: $mid-screen) {margin: .7em 0;}
}

@media (max-width: $mid-screen) {
.navbar-nav .nav-link {
    font-size: 14.5px;
    margin: 0 auto;
    font-weight: 600;
}
}

.heading.active {
    color: white;
}
.primary-heading--inverse {
    color: white;
}
.primary-heading_primary-color {
    color: $primaryColor;
}
.primary-heading__centered {
    text-align: center;
}
.medium-heading {
    font-size: 24px;
}
.small-heading {
    font-size: 18px;
    @media (max-width: 991px) { font-size: 14px;}
}
.small-heading__features {
  font-size: 17px;
}
.heading--primary-color {
    color: $primaryColor;
}
.heading__product-tour {
    margin-left: -3px;
    text-align: left;
}
.relative-underlay {
    position: relative;
}
.hidden {
    display: none;
}
.fancy-heading {
    margin-top: 0;
    font-size: 39px;
    font-family: $secondaryFont;
    position: absolute;
    z-index: 3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    letter-spacing: 2px;
    text-transform: none;
}
.fancy-heading--dark {
    color: #7fde75;
    color: #8fd61e;
    z-index: 4;
    display: none;
}
.fancy-heading--accent {
    color: rgba(21, 156, 251, 0.27);
    position: relative;
    z-index: 0;
    top: 3px;
    left: 3px;
}

.fancy-heading--alt {
    margin-top: 0;
    font-size: 49px;
    font-family: $secondaryFont;
    text-transform: uppercase;
}

.overline-heading:before {
    background: rgba(16, 71, 109, 0.5803921568627451);
    -webkit-border-radius: 2px;
       -moz-border-radius: 2px;
            border-radius: 2px;
    height: 4px;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
         transform: translateX(-50%);
    width: 60px;
}
.heading-wrapper {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    -webkit-flex-grow: 0;
       -moz-box-flex: 0;
            flex-grow: 0;
    z-index: 4;
    pointer-events: none;
    padding-top: 30px;
    max-width: 850px;
    text-align: center;
    padding-bottom: 0px;
    position: relative;
    @media (max-width: $mid-screen){padding: 30px 30px 20px;}
    
}

.subhead {
    font-size: 25px;
    line-height: 1.14815;
    font-weight: 400;
    letter-spacing: .008em;
    font-family: $primaryFont;
    margin: 1em auto;
}

.carousel-control-next, .carousel-control-prev {
    opacity: 1;
}

.container__homepage-reviews {
  position: relative;
}

.controls {
  position: absolute;
    top: 50%;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    width: 15%;
    font-size: 28px;
    color: black;
    text-align: center;
    opacity: .5;
    -webkit-transition: opacity .15s ease;
    -o-transition: opacity .15s ease;
    -moz-transition: opacity .15s ease;
    transition: opacity .15s ease;
}

.left-control {
  left: -2em;
  @media (max-width: $small-screen) {left: -3em;}
  @media (max-width: 700px) {left: -2em;}
  @media (max-width: 576px) {left: -0.1em;}
}

.right-control {
  right: -2em;
  @media (max-width: $small-screen) {right: -3em;}
  @media (max-width: 700px) {right: -2em;}
  @media (max-width: 576px) {right: -0.1em;}
}

.slide-col {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}


.overline-heading {
    font-family: $primaryFont;
    margin: 0em auto;
    padding-top: 30px;
    position: relative;
    text-align: center;
    font-size: 50px;
    line-height: 1.07143;
    font-weight: 600;
    letter-spacing: -.005em;
}

.heading-container {
    max-width: 800px;
    margin: 1.4em auto;
    line-height: 1.4;
}
.paragraph {
    font-size: 16px;
}
.col--standard {
    padding: 3em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
            @media (max-width: $small-screen) { display: unset; border: 3px solid $primaryColor;
                border-bottom: none;}
}
.col--standard-mobile {
    @media (max-width: $mid-screen){padding: 3em;}
}
.col__tour-image {
    text-align: center;
}
.col__tour-item {
  @media (max-width: $mid-screen) and (min-width: 650px){ max-width: 80%;}
 
}
.paragraph-lead {
    font-size: 21px;
    line-height: 28px;
    margin: 1em auto;
    @media (max-width: $small-screen){
        text-align: left;
        font-size: 18px;}
}


.paragraph-large-mobile {
    @media (max-width: $small-screen) {
        font-size: 21px;
        padding: 1em;
    }
} 
.paragraph--inverse {
    color: white;
}
.paragraph--centered {
    text-align: center;
}
.paragraph--secondary {
    font-family: $secondaryFont;
    font-weight: 600;
    color: #a1d156;
    font-size: 25px;
}
.paragraph-display {
    max-width: 800px;
    margin: 1em auto;
    text-align: center;
}
.paragraph__referrals {
    @media (max-width: $mid-screen) {color: #313030;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;    
        margin-bottom: 2em;}
        @media (max-width: $small-screen) { text-align: left;}
}
.paragraph__section-homepage {
    padding: 0.5em 5em;
    text-align: center;
    font-size: 17px;
    font-family: $secondaryFont;
    line-height: 28px;
    @media (max-width: $mid-screen) {
        padding: 1em 2em;
        font-size: 16px;
        line-height: 38px;
    }
}
.page-section--first {
    padding: 7em 2em 4em;
}
.page-section {
    padding: 4em 2em 4em;
    @media (max-width: $mid-screen) {padding: 2em;}
}
.page-section__faq {
  @media (max-width: $mid-screen) {margin: 4em 0;}
  
}
.page-section--accent-1 {
    background-color: rgba(242, 242, 242, 0.6784313725490196);
}

.page-section--accent-1 {
    background-color: rgba(138, 234, 185, 0.13);
}




.page-section--flush {
    padding: 0;
    margin: 0;
}

.page-section--mobile-flush {
    @media (max-width: $mid-screen) {padding: 0; margin: 0;}
}

.page-section__refer-a-friend {
    @media (min-width: 1200px) {padding: 5em 10em 10em;}
    @media (min-width: $mid-screen) {padding: 5em 5em 10em;}
    
}
.page-section__privacy, .page-section__terms {
    padding: 1em 2em;
}

.svg__installation {
  margin-top: 8em;
  margin: 6em auto; 
  @media (max-width: $mid-screen) {width: 70%;margin-top: 1em;}
  @media (max-width: 700px) {width: 90%;}
  @media (max-width: 600px) {width: 115%; margin-left: -10%;}
}


.container__privacy, .container__terms {
    width: 56rem;
    @media (max-width: $mid-screen) {width: 100%;}
}

.row__refer-a-friend {
    background: white;
    @media (max-width: $small-screen) { 
        -webkit-box-orient: vertical; -webkit-box-direction: reverse; -webkit-flex-direction: column-reverse; -moz-box-orient: vertical; -moz-box-direction: reverse; -ms-flex-direction: column-reverse; flex-direction: column-reverse;}
}

.row__thanks {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.flush {
    margin: 0 !important;
    padding: 0 !important;
}
.video--full {
 width: 100%;
}
.video__referrals {
    height: 100%;
    @media (max-width: $mid-screen){height: 400px;}
}
.page-section__referrals {
    background-image: url('https://staging.lanaibright.com/img/Confetti@3x.png');
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    @media (max-width: $mid-screen) { padding: 5em 2.5em;}
}
.paragraph__call-us {
    font-weight: 600;
}
.accent-3 {
    color: $primaryColor;
}
.section-contact {
    background-image: url("https://staging.lanaibright.com/img/contact-bg@3x.png");

    -webkit-background-size: cover;

       -moz-background-size: cover;

         -o-background-size: cover;

            background-size: cover;
    background-position: 50%;
    @media (max-width: $mid-screen) { background-color: #0071be;
      background-image: none; padding: 4em 0;}
      @media (max-width: $small-screen) {  padding: 2em 0;}

    
    
}

.mobile-break {
    display: none;
    @media (max-width: $small-screen) {
        display: unset;
    }
}

.text-container--light {
    @media (max-width: $mid-screen) {
        padding: 25px;
        border: 3px solid $primaryColor;
        background: white;
    
    }
}
.form {
    max-width: 600px;
    margin: 0 auto;
}
.footer {
    padding: 1rem;
    text-align: center;
    -webkit-box-shadow: 0 -1px 0 0 #C9D2DA;
    -moz-box-shadow: 0 -1px 0 0 #C9D2DA;
    box-shadow: 0 -1px 0 0 #C9D2DA;
}
.footer .nav {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.navbar-header {
  -webkit-box-shadow: 0 1px 1px 0 rgba(45, 43, 43, 0.07);
     -moz-box-shadow: 0 1px 1px 0 rgba(45, 43, 43, 0.07);
          box-shadow: 0 1px 1px 0 rgba(45, 43, 43, 0.07);
  background-color: white;
  min-height: 68px;
}

@media (max-width: 1200px) {
    .navbar-container {
        max-width: 95%;
        padding: 0;
    }
}
.navbar-container {
    @media (max-width: 991px) {
    max-width: 100%;
    margin: 0;
    width: 100%;
    padding: 0;
    height: 68px; 
}
@media only screen and (min-width: 48em) {
    width: 100%;
}
}
.navbar {
    padding: 0 1em;
    font-size: 14px;
    font-weight: bold;
    @media (max-width: 991px) { height: inherit; padding: 0;-webkit-box-pack: center; -webkit-justify-content: center; -moz-box-pack: center; -ms-flex-pack: center; justify-content: center; }
}
.navbar-brand {
    margin-right: 40px;
    @media (max-width: 1200px) {margin-right: 20px;}
}

.logo {
    @media (max-width: 1050px) {width: 90px;}
    @media (max-width: $mid-screen) {width: 85px;}
}

.navbar-toggler {}
.navbar-toggler-icon {
    background: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}



.section--inverse {
    background-color: black;
    color: white;
}
.col-flush {
    padding: 0;
    margin: 0
}
.col__referral-video {
    min-height: 420px;
    @media (max-width: 991px) { max-height: 400px;}
}

.e-navicon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    top: 14px;
    left: 14px;
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    z-index: 4;
    @media (min-width: 991px) { display: none;}
}


.icon-bar {
    position: absolute;
    width: 30px;
    height: 6px;
    background: #232729;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    -webkit-transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    }

.icon-bar__top {
    top: 10px;
    left: 0px;
}

.icon-bar__bottom {
    bottom: 10px;
    left: 0px;
}
.e-navicon .icon-bar__bottom.active  {
    bottom: 17px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.e-navicon .icon-bar__top.active {
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 17px;
}

.navicon {

    height: -webkit-fit-content;

    height: -moz-fit-content;

    height: fit-content;
}
.navbar-collapse {
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
    @media (max-width: 991px) { 
        padding: 2em; 
        height: 100vh; 
        -webkit-box-pack: center; 
        -webkit-justify-content: center; 
           -moz-box-pack: center; 
            -ms-flex-pack: center; 
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
                background-color: rgba(0,86,179,.68);
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 0;
    width: 100%;
    }
}
.navbar-nav {
    height: 68px;
    @media (max-width: $mid-screen) { 
        height: auto;
        -webkit-box-align: end;
        -webkit-align-items: end;
           -moz-box-align: end;
            -ms-flex-align: end;
                align-items: end;
     }
}
.nav-item__header {
    white-space: nowrap;
    height: 68px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 .8em; 
    position: relative;
    @media (max-width: 991px) { border: none;}
}
.m-10 {
    margin-top: 10em;
}
.nav-link {
    font-size: 14px;
}

.navbar-expand-lg .navbar-nav {
    @media (min-width: 992px) {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
}

.nav-item__header:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 0;
    height: 3px;
    display: block;
    background: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.nav-item__header:hover:after {
    width: 100%;
    background: rgba(145, 202, 54, 0.64);
    background: rgba(0, 86, 179, 0.88);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    @media (max-width: 991px) { margin-bottom: 15px; top: unset;}
}
a:hover {
    text-decoration: none;
}

a:hover, .nav-item:hover a.nav-link, .nav-link:focus, .nav-link:hover, .navbar-nav .nav-link:hover {
    color: #0056b3;
}

.nav-item__header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    display: block;
    background: 0 0;
    -webkit-transition: all .3s ease-in-out 0s;
    -o-transition: all .3s ease-in-out 0s;
    -moz-transition: all .3s ease-in-out 0s;
    transition: all .3s ease-in-out 0s;
    @media (max-width: 991px) { margin-bottom: 15px; top: unset;}
}

.navbar-nav .nav-link {
    font-family: $primaryFont;
    font-weight: 600; 
    padding: 0;
    font-size: 13px;
    color: #1b1c1d;
    color: #4d5154;
    letter-spacing: 0.3px; 
    font-family: $secondaryFont;
    text-transform: uppercase;
    @media (max-width: 1200px) {font-size: 12.5px;}
    @media (max-width: 991px) { font-size: 18px; color: white;}
}

/*
.collapsing {
    position: absolute;
    top: 0;
    height: 100vh;
    overflow: hidden;
    -webkit-transition: opacity .35s ease;
    -o-transition: opacity .35s ease;
    -moz-transition: opacity .35s ease;
    transition: opacity .35s ease;
}*/

.navbar-light .navbar-nav .nav-link:active {
    color: #404549;
}
.nav--vert {
    border: 1px solid #C9D2DA;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}
.nav-item--vert {
    border: none;
}
.nav__faq {
    @media (max-width: $mid-screen) {margin: 0.7em 0;}
}
.nav-item--vert:hover {
    border: none;
}
.nav__utility {
    margin: 4em 0;
    @media (max-width: $mid-screen) {margin: 1em 0;}
}
.nav-item__utility {

    font-family: $secondaryFont;
    font-size: 14px;
    font-weight: 600;
}
.nav-link__utility {

    color: #2C2E3F;
}
.btn {
    font-size: 14px;
    font-weight: 400;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    background-color: #248CD4;
    padding: 12px 20px;
    line-height: 1;
    border: none;
    @media (max-width: 1200px) { 
        font-size: 12.5px;
        padding: 8px 15px;}
    @media (max-width: $mid-screen) {
        font-size: 16px;
        padding: 0.6em 1.3em;
    }
}
.btn-primary {}
.btn-primary:hover {
    border-color: #0069d9;
}

.btn--centered {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1.5em auto;
}

.btn-secondary {
    width: 120px;
    background-color: rgb(15, 96, 199);
    border: 1px solid #082282;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary.active{
    
background-color: #154971;
border-color: #103d6b;
}

.btn-group{
}

.btn-group__homepage>.btn-group__homepage:not(:last-child)>.btn, .btn-group__homepage>.btn:not(:last-child):not(.dropdown-toggle),.btn-group__homepage>.btn-group__homepage:not(:first-child)>.btn, .btn-group__homepage>.btn:not(:first-child) {
    
    border-radius: 100px !important;
    -webkit-border-radius: 100px !important;
    -moz-border-radius: 100px !important;
    -ms-border-radius: 100px !important;
    -o-border-radius: 100px !important;
}

.btn-list {
    font-family: $secondaryFont;
    font-weight: 600;
    font-size: 13px;
    color: gray;
    background-color: transparent;
    
    background-color: transparent;
    border: 3px solid transparent;
}
.btn-list.active, .btn-list:hover {
    border: 3px solid #159CFB;
    color: #159CFB;
}
.btn-pastel--green {
    background-color: #d0e8a8;
    color: #636161;
    margin: 1em 1em 1em 0;
}
.btn-pastel--blue {
    background-color: #cce6f9;
    color: #636161;
    margin: 1em 1em 1em 0;
}
.btn-pastel--blue:active,
.btn-pastel--blue:hover,
.btn-pastel--blue:not(:disabled):not(.disabled):active {
    background-color: #81c9ff;
    color: #636161;
}
.btn-pastel--green:active,
.btn-pastel--green:hover,
.btn-pastel--green:not(:disabled):not(.disabled):active {
    background-color: #d6f5a3;
    color: #636161;
}
.btn--center {
    margin: 2em auto;
    display: block;
}
.btn-cta {
    color: white;
    padding: 0.4em 3em;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    font-size: 18px;
    line-height: 25px;
    font-family: $secondaryFont;
    margin: 3em 0 1.2em;
    -webkit-box-shadow: 4px 2px 4px 0 rgba(0,0,0,0.17);
    -moz-box-shadow: 4px 2px 4px 0 rgba(0,0,0,0.17);
    box-shadow: 4px 2px 4px 0 rgba(0,0,0,0.17);
    outline: none;
}

.btn__faq {
  background-color: transparent;
  border: none;
}
.cta-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}


.column--text {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
}
.column__bio {
    margin: 1.4em; 
    max-width: 60%;
    @media (max-width: $mid-screen) { margin: 4em 0;}
    @media (max-width: $small-screen) { max-width: 100%;}
}
.column__bio:first-child {
    margin: 1em;
    @media (max-width: $mid-screen) { margin: 0;}
}

.title__bio {
    background: rgba(255, 165, 0, 0.47058823529411764);
    padding: 0.4em;
    color: black;
    font-size: 18px;
    font-weight: 900;
}
.cta-text {
    text-align: center;
    font-family: $secondaryFont;
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    margin: 0;
    @media (max-width: $small-screen) { display: none;}
}
.cta-text--dark {
    color: white;
}
.btn-cta-gradient {
    /*Permalink-usetoeditandsharethisgradient:;
    http:;
    //colorzilla.com/gradient-editor/#90c63d+0,0e5cad+100*/background: #90c63d;
    /*Oldbrowsers*/background: -moz-linear-gradient(-45deg, #90c63d 0%, #0e5cad 100%);
    /*FF3.6-15*/background: -webkit-linear-gradient(-45deg, #90c63d 0%,#0e5cad 100%);
    /*Chrome10-25,Safari5.1-6*/background: -webkit-linear-gradient(315deg, #90c63d 0%,#0e5cad 100%);
    background: -moz-linear-gradient(315deg, #90c63d 0%,#0e5cad 100%);
    background: -o-linear-gradient(315deg, #90c63d 0%,#0e5cad 100%);
    background: linear-gradient(135deg, #90c63d 0%,#0e5cad 100%);

    padding: 0.5em 1.2em;
    -webkit-border-radius: 20px;
       -moz-border-radius: 20px;
            border-radius: 20px;
    font-size: 16px;
    line-height: 20px;
    font-family: $secondaryFont;
    margin: 1em 0 1.2em;
    -webkit-box-shadow: 4px 2px 4px 0 rgba(0,0,0,.17);
       -moz-box-shadow: 4px 2px 4px 0 rgba(0,0,0,.17);
            box-shadow: 4px 2px 4px 0 rgba(0,0,0,.17);
    outline: none;
}
.text__hero-with {
    opacity: 0.7;
}
.text__hero-lanaibright {
    font-weight: 700;
    opacity: 1;
    font-size: 44px;
    margin-left: 0.2em;
    @media (max-width: $mid-screen) {
      font-size: 36px;
    }
    @media (max-width: 550px) {
      font-size: 28px;
    }
}
.btn-cta__hero {
    color: white;
    background: transparent;
    padding: .5em 1.2em;
    border: 3px solid white;
    text-transform: uppercase;
    padding: 8px 20px;
    width: auto;
    margin: 0;
    line-height: 1.42;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    cursor: pointer;
    @media (max-width: $mid-screen) {
    font-size: 16px;
    position: absolute;
    bottom: -2em;
    left: 20%;
    z-index: 99;
    /* color: black; */
    background: #0065dc;
    border-color: transparent;
    /* border: 3px solid #fff; */
    /* text-transform: uppercase; */
    padding: 2% 1%;
    width: 60%;}
}
.header-phone { 
    margin-left: 15px;
    font-size: 14px;
    color: #187bff;
    color: $primaryColor;
    @media (min-width: $mid-screen) and (max-width: 1200px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
                -webkit-align-self: start;
                    -ms-flex-item-align: start;
                        align-self: start;
    }
    @media (max-width: $mid-screen) {display: none;}
}

.divider {
    margin: 0 0.7em;
    @media (min-width: $mid-screen) and (max-width: 1200px) {
        display: none;
    }
}

button, input, optgroup, select, textarea {
    font-family: $primaryFont;
}

.hero-homepage {
  z-index: 2;
  position: absolute;
  top: 0;
  background-color: transparent;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  height: 100%;
  @media (max-width: 991px) { opacity: 1;}
}
.hero__homepage {
  max-height: 750px; 
  position: relative;
  overflow: hidden;
  @media (max-width: 991px) { overflow: visible;}
}

@media (max-width: 991px) {
    .auto-width-md {
        height: auto;
        width: 100%;
    }
}

.hero-content {
    height: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    background: rgba(0, 0, 0, 0.5019607843137255);
    @media (max-width: 991px) { margin: 0; height: 100%;}
}
.homepage-review-container {}
.reviews-container {

    max-width: 1200px;
    margin: 0 auto;
}
.reviews {
    margin: 5em 0;
}

.reviews .row-container {
  overflow: hidden;
}

.reviews__homepage {

    margin: 0;
}
.reviews-intro {
    text-align: center;
}
.reviews-header {}
.reviews-paragraph {}
.review {

    margin: 2em 0.2em;
}
.review-signature {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
               -moz-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.review__reverse {

    -webkit-box-orient: horizontal;

    -webkit-box-direction: reverse;

    -webkit-flex-direction: row-reverse;

    -moz-box-orient: horizontal;

    -moz-box-direction: reverse;

    -ms-flex-direction: row-reverse;

    flex-direction: row-reverse;
    text-align: right;
}
.review-img-container {}
.review-img {
    width: 100%;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    -moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}
.review-img__homepage {
    width: 60px;
    height: 60px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    margin-right: 1em;
}
.review-img__homepage {
    width: 120px;
    height: 120px;
    background-attachment: scroll;
    background-position: 50%;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    margin-left: 1em;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
}
.overlay-img-bottom {
    position: absolute;
    z-index: 3;
    right: 0;
    left: 0;
    text-align: center;
    bottom: 0;
    padding: 8px 10px;
    color: white;
    background-color: #0D74BC;
    font-family: $secondaryFont;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.08px;

}
.sq-underlay {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: -10px;
    bottom: -10px;
    background-color: rgba(6, 136, 218, 0.21);
}
.sq-underlay--sw--wide {
    left: -1.2em;
    bottom: -1.2em;
}
.sq-underlay--sw--ex-wide {
    left: -2.2em;
    bottom: -2.2em;
}
.sq-underlay--nw--wide {
  left: -1.2em;
  bottom: 1.2em;
  @media (max-width: $mid-screen) {
    left: 1.2em;
  bottom: -1.2em;
  }
}
.sq-underlay--nw--ex-wide {
  left: -2.2em;
  bottom: 2.2em;
  @media (max-width: $mid-screen) {
    left: 2.2em;
    bottom: -2.2em;
  }
}
.sq-underlay--ne--wide {
    left: 1.2em;
    bottom: 1.2em;
}
.sq-underlay--ne--ex-wide {
  left: 2.2em;
  bottom: 2.2em;
}
.sq-underlay--se--wide {
  left: 1.2em;
  bottom: -1.2em;
  @media (max-width: $mid-screen) {
    left: -1.2em;
    bottom: 1.2em;
  }
}
.sq-underlay--se--ex-wide {
    left: 2.2em;
    bottom: -2.2em;
@media (max-width: $mid-screen) {
    left: -2.2em;
    bottom: 2.2em;
  }
}
.sq-underlay--secondary {
    background-color: rgba(145, 202, 54, 0.24);
}
.sq-underlay--wide {
    width: 100%;
    height: 100%;
    left: -10px;
    bottom: -10px;
    background-color: rgba(6, 136, 218, 0.21);
}
.primary-bg {
    position: absolute;
    z-index: -1;
    left: -10px;
    bottom: -10px;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 136, 218, 0.21);
}
.primary-bg--sw--wide {
    left: -1.2em;
    bottom: -1.2em;
}
.review-img-secondary-bg {
    position: absolute;
    z-index: -1;
    left: 10px;
    bottom: 10px;
    width: 100%;
    height: 100%;
    background-color: rgba(145, 202, 54, 0.24);
}
.review-tour-text {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    padding: 0 10px;
    color: white;
    background-color: #0D74BC;
    font-family: $secondaryFont;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.08px;
}
.review-tour-text:hover {
    text-decoration: none;
    color: white;
    background-color: #a0d24d;
    -webkit-transition: 0.3s all ease-out;
    -o-transition: 0.3s all ease-out;
    -moz-transition: 0.3s all ease-out;
    transition: 0.3s all ease-out;
}
.review-float {
    position: relative;
}
.review-tour-icon {}
.review-text-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.review-reviewer {
    font-family: $primaryFont;
    font-weight: 600;
    font-size: 30px;
    color: #2C2E3F;
    letter-spacing: -1.11px;
    margin: 0;
}
.review-reviewer {
    font-size: 22px;
    letter-spacing: 0.5px;
}
.review-reviewer__homepage-large {
    font-size: 32px;
    letter-spacing: 0.5px;
}
.review-location {
    font-family: $secondaryFont;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #2C2E3F;
    letter-spacing: -0.08px;
    margin: 0.6em 0 0.4em;
}
.review-location__homepage {
    margin: 0 0 0.8em;
    font-size: 12px;
    color: $primaryColor;
}

.review-location__homepage-large {
    font-size: 16px;
    color: $primaryColor;
    font-weight: 900;
}

.review-line {
    width: 100%;
}
.review-review {
    font-size: 15px;
    color: #070707;
    margin-top: 0.3em;
}
.review-review__homepage {
    min-height: 160px;
}
.carousel {
    height: 80%;
    display: none;
}
.carousel-inner {
    height: 100%;
}
.carousel.active {
    display: block;
}
.carousel-item {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50%;
    height: 100%;
}

.carousel .modal-img {
    height: 100%;
}

.modal-img:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    height: 200px;
    width: 100%;
}



.modal-description {

    padding: 1em;
}

/* Reviews */
.review-btn {

    margin: 1em;
    padding: 0.7em 5px 0.7em 0;
    width: 120px;
    background-color: rgb(15, 96, 199);
    border: 1px solid #082282;
}
.review-btn:active,
.review-btn:hover {

    background-color: rgb(7, 107, 234);
    border: 1px solid #082282;
}

.review-body__homepage {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 3em;
    background: white;
    @media (max-width: 1024px) {
      padding: 3em 2em;
      font-size: 12px;
    }
    @media (max-width: $mid-screen) {
      padding: 0em 4em 5em;
    }
}
.leave-review-section {
    background-image: url("https://staging.lanaibright.com/img/leave-review-bg.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50%;
    text-align: center;
    color: white;
}
.footer-container {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.footer-nav-column {
    display: contents;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 991px) {
    .footer-nav-column {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 3px;
    }
}
.nav__footer {}
.nav-link__footer {
    font-family: 'Helvetica Neue';
    color: #303C42;
    font-size: 14px;
    font-weight: bold;
    @media (max-width: $mid-screen) {
    padding: 0.5rem 0.7rem;
    }
    @media (max-width: 500px) {
        padding: 0.5rem 0.5rem;
        }
}
.nav-link__footer-icon {
    font-weight: 400;
    padding: 0.8em;
}
.nav__footer-icons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}
.copy {
    font-family: $secondaryFont;
    font-weight: medium;
    font-size: 10px;
    color: #9CA0A3;
}
.video__homepage {
  width: 100%;
  display: block;
}
@media (max-width: $mid-screen) {
  .video__homepage {
    height: auto;
    width: 100%;
  }
}

.section__locations {
}
.section__locations::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;   
    width: 100%;
    height: 100%;
    background-image: url("https://staging.lanaibright.com/img/florida@3x.png");
    -webkit-background-size: 60% 60%;
    -moz-background-size: 60%;
    -o-background-size: 60%;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: 70% 50%;
    top: 0;
    @media (max-width: 991px) { background-position: 50% 20%; -webkit-background-size: 100% 100%; -moz-background-size: 100%; -o-background-size: 100%; background-size: 100%; opacity: 0.2;}
  }
.graphic-wave {
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: -15px;
    right: 0;
    z-index: -1;
    opacity: 0.4;
    height: 100vh;
    @media (max-width: 800px) {height: 70vh;}
    @media (max-width: 700px) {height: 65vh; top: 2%;} 
    @media (max-width: 600px) {height: 55vh; top: 2%;}
    @media (max-width: 500px) {height: 40vh; top: 2%;}
}
a.call-overlay:hover {
    background-color: #137eff;
    color: white;
    text-decoration: none;    
}

.graphic-wave__photo-tour{
    left: 0;
    top: 21%;
    height: 75%;
    right: 0;
    z-index: -1;
    opacity: .4;
    @media (max-width: $mid-screen) {
        top: 10%;
    height: 100%;
    }
}
.graphic-line {
    height: 3.5px;
    background-color: $primaryColor;
    width: 50%;
    margin-top: 5em;
    margin-bottom: 3em;
    @media (max-width: $mid-screen) {margin-top: 1em; margin-bottom: 1em;}
}

.graphic-process {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
}
.locations-intro {
    padding-top: 10em;
    margin-left: 10em;
    @media (max-width: 991px) { padding: 0; margin: 0;}
}
.address-container {
  width: 300px;
    text-align: center;
    margin: 0 auto;
}
.address__footer {
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
  color: gray;
  letter-spacing: 0.05em;
}
.row__location {
    @media (max-width: 991px) { padding: 4em 2em;}    
}
.row__tour {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
            width: 95%;
            margin: 0 auto;
            max-width: 1200px;
            @media (max-width: $mid-screen) and (min-width: $small-screen) { width: 100%;}    
}
.row__faq {
  padding: 3em;
  @media (max-width: 1200px) {padding: 3em 0;}  
} 

.main {
    margin-top: 67px;
    position: relative;
}
.map {
    width: 100%;
    height: 400px;
}
.map-text {
    text-align: center;
}
.list-group {
    background-color: white;
    border-radius: .25rem;
    -webkit-border-radius: .25rem;
    -moz-border-radius: .25rem;
    -ms-border-radius: .25rem;
    -o-border-radius: .25rem; 
}

.list-group-item {
  @media (max-width: $mid-screen) {
    background-color: rgba(54,169,253,.0705882);
    border-color: rgba(0,86,179,.88);
  }
}

.list-group-item:hover {
  @media (max-width: $mid-screen) {
    background-color: rgba(54,169,253,.0705882);
    border-color: rgba(0,86,179,.88);
  }
}

.list-group-item.active, .list-group-item-action:active {
    background-color: rgba(54, 169, 253, 0.07058823529411765);
    border-color: $primaryColorTransparent;
    color: #495057;
}

.container__utility {
    @media (max-width: $mid-screen) {
    max-width: 100% }
}


.container__utility .tab-content > .active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}
.container__product-intro {
    max-width: 1040px;
}
.product-intro {
    padding-top: 8em;
    padding-left: 2em;
}
.emphasis {
    font-style: italic;
}
.bold-and-big {
    font-size: 1.2em;
    font-weight: 900;
    vertical-align: middle;
    font-family: Arial, Helvetica, sans-serif;
}
.rightof-bold-and-big {
    vertical-align: middle;
    margin-left: 0.2em;
}
.vert-nav {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -moz-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 300px;
}
.row__bios {
  margin-top: 4em;
  
  @media (max-width: $mid-screen) {margin-top: 2em;}
}

.row-container {
    position: relative;
}

.behind {
    position: absolute;
    top: 15%;
    left: 250px;
    right: 0;
    width: 70%;
    z-index: -1;
    @media (max-width: 1024px) {width: 85%;} 
    @media (max-width: $mid-screen) {top: 5%; width: 80%;}
    
}
.behind:not(.next) {
  z-index: -2;
}


.behind .card-body {
  font-size: 12px !important;
  padding: 0;
  margin: 0;
  max-width: 50% !important;
  @media (max-width: $mid-screen) {max-width: 100% !important; display: none;}
}
.card-faq, .card-header-faq, .sidebar.accordion .card:first-of-type, .sidebar.accordion .card:last-of-type  {
 border: none;
} 

.card-faq, .card-header-faq {
  @media (max-width: $mid-screen) {border: 1px solid black;}
 } 

 .sidebar.accordion .card:last-of-type {
  @media (max-width: $mid-screen) {border-bottom: 1px solid black;}
 } 


 .sidebar .card-body {
  @media (max-width: $mid-screen) {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
 } 
 

.card-header-faq {
  background-color: white;
}
.sidebar button {
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
line-height: 1;
color: #000;
font-size: 15px;
letter-spacing: .4;
font-weight: 900;
letter-spacing: 0.4px;
text-decoration: none;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    -moz-transition: all .2s ease;
    transition: all .2s ease;
}

.sidebar button:hover,.sidebar button:active, .sidebar button.active, .sidebar .card-header:hover button, {
  color: $primaryColor;
  text-decoration: none;
      -webkit-transition: all .2s ease;
      -o-transition: all .2s ease;
      -moz-transition: all .2s ease;
      transition: all .2s ease;
  }

.pic-anim svg {
  width: 130px;
  margin-left: 40px;
}

.reels, #largereelspin, #smallreelspin {
  transform-box: fill-box;
  -webkit-transform-origin: center;
     -moz-transform-origin: center;
      -ms-transform-origin: center;
       -o-transform-origin: center;
          transform-origin: center;
}



.card-body__product-tour {
  padding: 0;
}

.review-review__homepage-large {
    font-size: 18px;
}

.col-centered {
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

.button {
            display: block;
            text-align: center;
            vertical-align: middle;
            padding: 10px 24px;
            border: none;
            background: $primaryColor;
            border-radius:0;
            font: normal normal bold 15px arial;
            color: #ffffff;
            text-decoration: none;
            max-width: 200px;
            -webkit-border-radius:0;
            -moz-border-radius:0;
            -ms-border-radius:0;
            -o-border-radius:0;
}
.button:hover,
.button:focus {
    background: #2497ff;
    color: #ffffff;
    text-decoration: none;
}
.button:active {
    background: #2497ff;
}
.button-mail:after{
    content:  "";
    display: inline-block;
    height: 14px;
    width: 14px;
    line-height: 14px;
    margin: 0 -4px -2px 4px;
    position: relative;
    top: 0px;
    left: 3px;
    background: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMzNC41IDMzNC41IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMzQuNSAzMzQuNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cGF0aCBkPSJNMzMyLjc5NywxMy42OTljLTEuNDg5LTEuMzA2LTMuNjA4LTEuNjA5LTUuNDA0LTAuNzc2TDIuODkzLDE2My42OTVjLTEuNzQ3LDAuODEyLTIuODcyLDIuNTU1LTIuODkzLDQuNDgxICBzMS4wNjcsMy42OTMsMi43OTcsNC41NDJsOTEuODMzLDQ1LjA2OGMxLjY4NCwwLjgyNywzLjY5MiwwLjY0LDUuMTk2LTAuNDg0bDg5LjI4Ny02Ni43MzRsLTcwLjA5NCw3Mi4xICBjLTEsMS4wMjktMS41MSwyLjQzOC0xLjQsMy44NjhsNi45NzksOTAuODg5YzAuMTU1LDIuMDE0LDEuNTA1LDMuNzM2LDMuNDI0LDQuMzY3YzAuNTEzLDAuMTY4LDEuMDQsMC4yNSwxLjU2MSwwLjI1ICBjMS40MjksMCwyLjgxOS0wLjYxMywzLjc4Ni0xLjczM2w0OC43NDItNTYuNDgybDYwLjI1NSwyOC43OWMxLjMwOCwwLjYyNSwyLjgyMiwwLjY1MSw0LjE1MSwwLjA3MyAgYzEuMzI5LTAuNTc5LDIuMzQxLTEuNzA1LDIuNzc1LTMuMDg3TDMzNC4yNywxOC45NTZDMzM0Ljg2NCwxNy4wNjYsMzM0LjI4NSwxNS4wMDUsMzMyLjc5NywxMy42OTl6IiBmaWxsPSIjRkZGRkZGIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=") no-repeat left center transparent;
    -webkit-background-size: 100% 100%;
       -moz-background-size: 100% 100%;
         -o-background-size: 100% 100%;
            background-size: 100% 100%;
}
.button-mail {
    display: block;
    width: 120px;
}

.image-view:hover {
    -webkit-transition: -webkit-transform .8s cubic-bezier(.35,0,0,1);
    transition: -webkit-transform .8s cubic-bezier(.35,0,0,1);
    -o-transition: -o-transform .8s cubic-bezier(.35,0,0,1);
    -moz-transition: transform .8s cubic-bezier(.35,0,0,1), -moz-transform .8s cubic-bezier(.35,0,0,1);
    transition: transform .8s cubic-bezier(.35,0,0,1);
    transition: transform .8s cubic-bezier(.35,0,0,1), -webkit-transform .8s cubic-bezier(.35,0,0,1), -moz-transform .8s cubic-bezier(.35,0,0,1), -o-transform .8s cubic-bezier(.35,0,0,1);
    transition: transform .8s cubic-bezier(.35,0,0,1),-webkit-transform .8s cubic-bezier(.35,0,0,1);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
     -ms-transform: scale(1.05);
      -o-transform: scale(1.05);
         transform: scale(1.05);
    -webkit-transition: transform .8s cubic-bezier(.35,0,0,1);
    -moz-transition: transform .8s cubic-bezier(.35,0,0,1);
    -ms-transition: transform .8s cubic-bezier(.35,0,0,1);
    -o-transition: transform .8s cubic-bezier(.35,0,0,1);
}

.image-view {
    -webkit-transition: -webkit-transform .6s cubic-bezier(.35,0,0,1);
    transition: -webkit-transform .6s cubic-bezier(.35,0,0,1);
    -o-transition: -o-transform .6s cubic-bezier(.35,0,0,1);
    -moz-transition: transform .6s cubic-bezier(.35,0,0,1), -moz-transform .6s cubic-bezier(.35,0,0,1);
    transition: transform .6s cubic-bezier(.35,0,0,1);
    transition: transform .6s cubic-bezier(.35,0,0,1), -webkit-transform .6s cubic-bezier(.35,0,0,1), -moz-transform .6s cubic-bezier(.35,0,0,1), -o-transform .6s cubic-bezier(.35,0,0,1);
    transition: transform .6s cubic-bezier(.35,0,0,1),-webkit-transform .6s cubic-bezier(.35,0,0,1);
    height: inherit;
}

.image-view__container {
    position: relative;
    top: -7px;
    left: -7px;
    height: -webkit-calc(100% + 14px);
    height: -moz-calc(100% + 14px);
    height: calc(100% + 14px);
    width: -webkit-calc(100% + 14px);
    width: -moz-calc(100% + 14px);
    width: calc(100% + 14px);
}

.image-view__content {
    width: 100%;
    height: 100%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
}

.zoom {
-moz-transition: -moz-transform 6.5s ease-out 0s;
-o-transition: -o-transform 6.5s ease-out 0s;
-webkit-transition: -webkit-transform 6.5s ease-out;
-webkit-transition-delay: 0s;
-webkit-transition: -webkit-transform 6.5s ease-out 0s;
transition: -webkit-transform 6.5s ease-out 0s;
-moz-transition: transform 6.5s ease-out 0s, -moz-transform 6.5s ease-out 0s;
transition: transform 6.5s ease-out 0s;
transition: transform 6.5s ease-out 0s, -webkit-transform 6.5s ease-out 0s, -moz-transform 6.5s ease-out 0s, -o-transform 6.5s ease-out 0s;
}
.zoom:hover {
    -moz-transform: scale(1.25, 1.25);
    -ms-transform: scale(1.25, 1.25);
    -webkit-transform: scale(1.25, 1.25);
    -o-transform: scale(1.25, 1.25);
       transform: scale(1.25, 1.25);

}

.picture-swipe, .picture-swipe-two {
  background: transparent;
  -webkit-filter: drop-shadow(3px 3px 3px rgba(33, 33, 33, 0.166));
  filter: drop-shadow(3px 3px 3px rgba(33, 33, 33, 0.166));
}

.subheading__hero {
    color: white;
    font-size: 30px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-family: $secondaryFont;
    margin: -0.7em 0 1em;
    @media (max-width: $mid-screen){
        font-size: 20px;}
}
.page-section__features {
    text-align: center;
    padding: 4em 2em 8em;
}
 
.page-section__features-product-tour {
  padding: 0;
  margin-bottom: 4em;
  @media (max-width: $mid-screen){margin-top: 4em; }
}
.page-section__tour {
    position: relative;
    margin: 2em auto;
    max-width: 1800px;
    margin-bottom: 8em;
}
.page-section__video-tour {
  @media (max-width: $mid-screen){
    margin-bottom: 4em;}
}
.page-section__homepage-reviews {
    margin: 2em 0;
    @media (max-width: $mid-screen){
      margin: 0;
      padding: 0;
    }
}
.card-icon {
    margin: 1em;
}
.card-row__features {
    margin: 2em auto;
    max-width: 1100px;
    @media (max-width: $small-screen) {
      margin: 0em auto 2em;
    }
}
.card__features {
    border: 0; 
}
.card-col__features {
  @media (max-width: $large-screen) and (min-width: $mid-screen) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}
.card-body__features {}
.card-icon__features {
    width: 15%;
}
.card-title__features {
    font-size: 14px;
    font-weight: 700;
    @media (max-width: $large-screen) {
      font-size: 18px;
    }
    
}
.card-text__features {
    font-size: 14px;
}

.card__homepage {
    border: none;
    -webkit-box-shadow: 3px 3px 3px gray;
       -moz-box-shadow: 3px 3px 3px gray;
            box-shadow: 3px 3px 3px gray;
}

.card-img-top-large {
    -webkit-border-radius: -webkit-calc(.25rem - 1px);
       -moz-border-radius: -moz-calc(.25rem - 1px);
            border-radius: calc(.25rem - 1px);
}
.card-large {
    border: none;
}

.feature-img {
    -webkit-transition: background-image 0.2s ease-in-out;
    -o-transition: background-image 0.2s ease-in-out;
    -moz-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;
}
.feature-img.active {
    background: transparent;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
}
.featured-images {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
     -moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

.featured-image {
  opacity: 0;
  display: none;
}
.featured-image.active {
  opacity: 1;
  display: block;
  background: transparent;
  transition: opacity 1s;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
}
.preload {
    display: none;
}

.img__tour-item-large {
    width: 50%;
}
.img__tour-item {
         -webkit-background-size: cover;
         -moz-background-size: cover;
         -o-background-size: cover;
         background-size: cover;
         background-position: 50%;
         height: 300px;
         -webkit-box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
         -moz-box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
         box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
         -webkit-transition-property: color,background-color,-webkit-box-shadow,-webkit-transform;
         transition-property: color,background-color,-webkit-box-shadow,-webkit-transform;
         -o-transition-property: color,background-color,box-shadow,-o-transform;
         -moz-transition-property: color,background-color,box-shadow,transform,-moz-box-shadow,-moz-transform;
         transition-property: color,background-color,box-shadow,transform;
         transition-property: color,background-color,box-shadow,transform,-webkit-box-shadow,-moz-box-shadow,-webkit-transform,-moz-transform,-o-transform;
         transition-property: color,background-color,box-shadow,transform,-webkit-box-shadow,-webkit-transform;
         -webkit-transition-duration: .15s;
         -moz-transition-duration: .15s;
         -o-transition-duration: .15s;
         transition-duration: .15s;
         border-radius: 5px;
         -webkit-border-radius: 5px;
         -moz-border-radius: 5px;
         -ms-border-radius: 5px;
         -o-border-radius: 5px;
}
.img__tour-item:hover {
    -webkit-box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3);
    -moz-box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3);
         box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
     -ms-transform: translateY(-2px);
      -o-transform: translateY(-2px);
         transform: translateY(-2px);
}
.img-aspect-75 {
    width: 100%;
    height: 20vh;
    @media (max-width: 800px) {height: 25vh;}
    @media (max-width: 700px) {height: 30vh;}
    @media (max-width: 600px) {height: 25vh;}
    @media (max-width: 500px) {height: 15vh;}
}
.call-overlay {
    position: fixed;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    background-color: rgba(24, 123, 255, 0.85);
    background-color: $primaryColorTransparent; 
    border: 0px solid white;
    z-index: 100;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.2);
       -moz-box-shadow: 0 1px 3px rgba(0,0,0,.2);
            box-shadow: 0 1px 3px rgba(0,0,0,.2); 
            -webkit-box-shadow: 0 13px 27px -5px rgba(50,50,93,.1), 0 8px 16px -8px rgba(0,0,0,.1); 
               -moz-box-shadow: 0 13px 27px -5px rgba(50,50,93,.1), 0 8px 16px -8px rgba(0,0,0,.1); 
                    box-shadow: 0 13px 27px -5px rgba(50,50,93,.1), 0 8px 16px -8px rgba(0,0,0,.1); 
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            color: white;
    
            font-size: 13px;
            bottom: 15px;
            text-align: center;
            font-weight: 400;
            line-height: 1.3em;
            letter-spacing: -.5px;
            word-spacing: -1px;
}
.call-overlay--emphasis {
    font-weight: 900;
    display: contents;
    font-size: 18px;
    color: #FFEB3B;
}

.call-overlay-right {
    
    right: 15px;
}

.call-overlay-left {
    left: 15px;
}

.modal-dialog {
    max-width: 60%;
    height: 90%;
    @media (max-width: $mid-screen) {
        height: 99.5%;
        margin: 0.1em auto;
        max-width: 99%;
        -webkit-border-radius: 10px;
           -moz-border-radius: 10px;
                border-radius: 10px;}
}
.modal-img {
    -webkit-border-top-right-radius: 10px;
       -moz-border-radius-topright: 10px;
            border-top-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
       -moz-border-radius-topleft: 10px;
            border-top-left-radius: 10px;
    -webkit-background-size:cover;
       -moz-background-size:cover;
         -o-background-size:cover;
            background-size:cover;
            width:100%;
            height:80%;
            -o-object-fit: cover;
               object-fit: cover;
}



.modal-body {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.92);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    pointer-events: all;
    height: 100%;
    width: 100%;
}

.close {
        position: absolute;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 1;
        right: 10px;
        top: 10px;
}
button.close {
    padding: 0.1em 0.3em;
    background-color: white;
    border: 1px solid transparent;
    -webkit-border-radius: 500px;
       -moz-border-radius: 500px;
            border-radius: 500px;
            z-index: 999;
}

.close:hover, .close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    opacity: .75;
    background: #e7eae9;
}
.modal-description {
    font-family: $secondaryFont;
    margin: 1em;
    color: white;
}

.sitemap-list-items {
    padding: 0;
    margin: 0;
}

.sitemap-list-item {
    list-style: none;
}
.heading-sitemap { 
    margin-bottom: 1.5em;
    font-size: 28px;
    text-transform: uppercase;
}
.sitemap-icon {
    margin-right: 5px;
}

.heading-sitemap--tours {
    @media (max-width: $mid-screen) {
    padding-left: 5px;
    }
}

.site {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .site-content {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
       -moz-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }

  .utility-description {
    @media (min-width: 1200px) { max-width: 480px;}
  }

  .page-section__utility {
    @media (min-width: 991px) and (max-width: 1024px) {padding: 4em 1em;}
    
}
  .container__utility {
    @media (min-width: 992px) and (max-width: 1024px) {width: 100%;}
  }

  .accordion .card:first-of-type {
    border-bottom: 1px solid rgba(0,0,0,.125);

  }

  .accordion .card .card-header:last-of-type {
   margin: 0;
 }